import React, { useState } from 'react';
import { isBefore, isAfter } from 'date-fns';
//@ts-ignore
import * as styles from './alert-banner.module.css';
import CloseButton from '../Global/CloseButton';
import { graphql } from 'gatsby';

import {
    KontentRichText,
    BrandColor,
    KontentStringValue,
    CTALocation,
} from '../../../types/KontentProps';
import RichText from '../Global/RichText';
import { getColor, getMultiChoice } from '../Global/DataUtils/index';
import CtaLink from '../Global/CtaLink';
import { BuildLinkHref } from '../Global/BuildLinkHref';

type LinkedAlert = {
    id: number;
    elements: {
        message: KontentRichText;
        bar_color: BrandColor;
        font_color: BrandColor;
        activation_date_time: {
            value: Date;
        };
        expiration_date_time: {
            value: Date;
        };
        cta_configuration__cta_label?: KontentStringValue;
        cta_configuration__cta_location?: CTALocation;
        cta_configuration__cta_location_anchor_point?: KontentStringValue;
        cta_configuration__cta_window_action?: {
            value: {
                codename: 'same_window' | 'new_window';
            }[];
        };
        cta_label?: KontentStringValue;
        cta_location?: CTALocation;
        cta_location_anchor_point?: KontentStringValue;
        cta_window_action?: {
            value: {
                codename: 'same_window' | 'new_window';
            }[];
        };
    };
};
interface Props {
    untitled_linked_items: {
        value: LinkedAlert[];
    };
}

const AlertBanner: React.FC<Props> = ({ untitled_linked_items, ...props }) => {
    const [visible, setVisible] = useState(true);
    const [currentDate, setCurrentDate] = useState<Date | null>(null);
    // const currentDate = new Date();
    const barColorBkg = getColor(
        untitled_linked_items?.value[0]?.elements?.bar_color
    );
    const barFontColor = getColor(
        untitled_linked_items?.value[0]?.elements?.font_color
    );

    const handleCloseClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setVisible(false);
    };

    const closeBtnColor = () => {
        if (visible === true) {
            if (barColorBkg === '#f5f4f2' || barColorBkg === '#ffffff') {
                return (
                    <CloseButton
                        visualStyle="dark"
                        onClick={handleCloseClick}
                    />
                );
            } else {
                return (
                    <CloseButton
                        visualStyle="light"
                        onClick={handleCloseClick}
                    />
                );
            }
        }
    };

    const renderAlerts = (alert: LinkedAlert, alertIdx: number) => {
        let barColor = barColorBkg || '#f06623';
        let fontColor = barFontColor || '#fff';
        if (
            alert?.elements?.cta_configuration__cta_label?.value ||
            alert?.elements?.cta_configuration__cta_location?.value?.[0]
                ?.elements?.slug?.url
        ) {
            const ctaWindowAction = getMultiChoice(
                alert.elements.cta_configuration__cta_window_action
            );
            var ctaTarget = ctaWindowAction === 'new_window' ? '_new' : '_self';
            const ctaAnchor =
                alert?.elements?.cta_configuration__cta_location_anchor_point;
            var ctaHref = BuildLinkHref(
                alert?.elements?.cta_configuration__cta_location,
                ctaAnchor
            );
        }

        const alertBannerContent = (
            <div
                className={styles.alert}
                key={alertIdx}
                style={{ backgroundColor: barColor, color: fontColor }}
            >
                {RichText(alert.elements.message)}

                {alert.elements?.cta_configuration__cta_label?.value && (
                    <div className={styles.alertChild}>
                        {alert?.elements.cta_configuration__cta_label?.value ? (
                            <style>{`#alert-cta-link-${alertIdx}:hover { 
                            border-color: ${fontColor} !important; 
                            color: ${barColor} !important;
                            background-color: ${fontColor} !important;
                        }`}</style>
                        ) : (
                            <></>
                        )}
                        {alert?.elements?.cta_configuration__cta_label
                            ?.value && (
                            <CtaLink
                                color={fontColor}
                                hoverBgColor={fontColor}
                                hoverFontColor={barColor}
                                id={`alert-cta-link-${alertIdx}`}
                                href={ctaHref}
                                target={ctaTarget}
                            >
                                {
                                    alert.elements?.cta_configuration__cta_label
                                        ?.value
                                }
                            </CtaLink>
                        )}
                    </div>
                )}
            </div>
        );
        return alert?.elements?.cta_configuration__cta_location?.value?.[0]
            ?.elements?.slug?.url ? (
            <a style={{ width: '100%' }} href={ctaHref} target={ctaTarget}>
                {alertBannerContent}
            </a>
        ) : (
            alertBannerContent
        );
    };

    React.useEffect(() => {
        setCurrentDate(new Date());
    }, []);

    return (
        <section className={styles.banner}>
            {untitled_linked_items?.value.map((LinkedAlert, alertIdx) => {
                let newAcivationDate = new Date(
                    LinkedAlert.elements.activation_date_time.value
                );
                let newExpirationDate = new Date(
                    LinkedAlert.elements.expiration_date_time.value
                );
                if (
                    (currentDate &&
                        (isBefore(currentDate, newAcivationDate) ||
                            isAfter(currentDate, newExpirationDate))) ||
                    !visible
                ) {
                    return null;
                } else if (!currentDate) return null;
                else {
                    return (
                        <>
                            {renderAlerts(LinkedAlert, alertIdx)}
                            {closeBtnColor()}
                        </>
                    );
                }
            })}
        </section>
    );
};

export default AlertBanner;

export const fragmentAlertBanner = graphql`
    fragment AlertBanner on kontent_item_component___alert_banner {
        elements {
            untitled_linked_items {
                value {
                    ... on kontent_item_content_item___alert_config {
                        id
                        elements {
                            activation_date_time {
                                value
                            }
                            bar_color {
                                ...kontentColor
                            }
                            font_color {
                                ...kontentColor
                            }
                            expiration_date_time {
                                value
                            }
                            message {
                                value
                            }
                            cta_configuration__cta_window_action {
                                value {
                                    name
                                }
                            }
                            cta_configuration__cta_location_anchor_point {
                                value
                            }
                            cta_configuration__cta_location {
                                ...kontentCtaLocation
                            }
                            cta_configuration__cta_label {
                                value
                            }
                        }
                    }
                }
            }
        }
    }
`;
